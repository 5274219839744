import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Typography } from '@mui/material'

import Nominations from './selection/Nominations'
import Voting from './selection/Voting'
import Rating from './selection/Rating'
import Countdown from './selection/Countdown'
import Results from './selection/Results'
import Loading from '../components/Loading'

import { UserContext } from '../contexts/UserContext'


function SelectionPage() {

  document.title = 'Selection | AOTW';

  const { user } = useContext(UserContext)
  /*
    {
      fname: string
      lname: string
      pfp: string
      email: string
    }
  */

  const [initial, setInitial] = useState({phase: 'loading'}) // stores initial data (phase and google authentication key)
  const [messages, setMessages] = useState({}) // stores error and success messages
  /*
    {
      error: string
      success: string
    }
  */

  const navigate = useNavigate()

  
  // runs on page load (everything loaded once)
  useEffect(() => {
    // get inital data
    getInitial()
  }, [])
  let phase = initial.phase


  if(phase === 'loading' || initial.phase === 'loading') // not loaded yet
    return <Loading />
  console.log(initial.description)
  if(phase === 1)
    return <Nominations genre={initial.thisGenre} description = {initial.description}/>
  if(phase === 2) {
    if(initial.countdown){
      return <Countdown initial={initial} setInitial={setInitial}/>
    }
    return <Voting />
  }
  if(initial.ratingOpen)
    return <Rating />
  if(phase > 1) {
    navigate('/')
    return
  }
  return noDiscussionHTML();
  

  function getInitial() {
    const options = {
      method: 'GET',
      url: '/api/initial-data'
    }
    axios.request(options).then((res) => {
      setInitial(res.data)
      console.log(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  /*
      NO DISCUSSION
  */
  function noDiscussionHTML() {
    return (
      <div className='page'>
        <Typography variant="h1">No discussion planned in the next 7 days</Typography>
      </div>
    )
  }
}

export default SelectionPage;
