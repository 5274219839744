import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react"
import { Button, Menu, MenuItem, Avatar, Typography, AppBar, Box, Stack, Divider, Drawer, Link } from "@mui/material";
import axios from 'axios'
import MenuIcon from '@mui/icons-material/Menu';

import { UserContext } from "../contexts/UserContext";
import { MenuContext } from "../contexts/MenuContext"
import { MobileContext } from "../contexts/MobileContext"

import SignIn from "./SignIn"

function NavBar() {

    const navigate = useNavigate();

    const [google, setGoogle] = useState(null) // holds google api key
    const [profileAnchor, setProfileAnchor] = useState(null) // profile anchor point (for opening profile menu)
    const [phase, setPhase] = useState(null) // phase to display (nominate, vote, winning album, etc)

    const { openMenu, setOpenMenu } = useContext(MenuContext) // if menu is open (only used on mobile since their navbar is a separate menu)
    const { user, setUser } = useContext(UserContext) // user info
    const { mobile } = useContext(MobileContext) // if user is using mobile


    useEffect(async () => {
        // get saved information
        let fname = localStorage.getItem('fname')
        let lname = localStorage.getItem('lname')
        let email = localStorage.getItem('email')
        let pfp = localStorage.getItem('pfp')
        if (fname && lname && email) {
            let dues = await getDuesInfo(email)
            setUser({ firstName: fname, lastName: lname, email: email, pfp: pfp, registration_data: dues })
        }


        // get google api key from backend (probably bad but i dont think anyone could do any harm with it)
        let options = {
            method: 'GET',
            url: '/api/google-id'
        }
        axios.request(options).then((res) => {
            setGoogle(res.data.google)
        })

        // load initial data (phase and such)
        options = {
            method: 'GET',
            url: '/api/initial-data'
        }
        axios.request(options).then((res) => {
            if (res.data.err) return
            let phase = res.data.phase
            if (phase === 1)
                setPhase("nominate")
            else if (phase == 2) {
                if (res.data.countdown)
                    setPhase("countdown")
                else
                    setPhase("vote")
            }
            else if (res.data.ratingOpen)
                setPhase("rate")
            else if (phase > 2)
                setPhase("winning album")

            else
                setPhase(null)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    // desktop navbar
    if (!mobile) {
        return (
            <AppBar className="banner" position='sticky' sx={{ height: '66px', width: '100%', backgroundColor: '#843a3a' }}>
                <Stack className="more-header" direction='row'>
                    <a href="/" onClick={() => navigate("/")}><img src="images/logos/transparent_logo_cream.png" height="50" alt="Link to Album of the Week Home"></img></a>

                    <Button href='/about' onClick={() => navigate('/about')}>about</Button>
                    {phase !== null && phase !== 'winning album' &&
                        <Button href='/selection' onClick={() => navigate('/selection')}>{phase}</Button>
                    }
                    <Button href='/ratings' onClick={() => navigate('/ratings')}>past discussions</Button>
                    {!user.registration_data.paid && <Button href='/register' onClick={() => navigate('/register')}>register</Button>}
                    <Button href="https://tamu.estore.flywire.com/products?storeCatalog=8752" target="_blank">store</Button>


                    <Box sx={{ position: 'absolute', right: '10px', display: 'flex', alignItems: 'center' }}>
                        {user.email && <>
                            <div id="rainbowContainer">
                                <a href="https://linktr.ee/draggieland" target="_blank" id="rainbowRibbonLink">
                                    <img class="rainbowRibbon" src="/images/rainbowribbon.png" alt="Rainbow Ribbon" title="Draggieland Linktree"/>
                                </a>
                            </div>
                            <Box>
                                <Button onClick={(event) => { setProfileAnchor(event.currentTarget) }}>
                                    <Avatar src={user.pfp}></Avatar>
                                </Button>
                                <Menu anchorEl={profileAnchor} onClose={() => { setProfileAnchor(null) }} open={Boolean(profileAnchor)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} disableScrollLock={true}>
                                    <MenuItem href='/profile' onClick={() => navigate('/profile')}>profile</MenuItem>
                                    <Divider></Divider>
                                    <MenuItem onClick={logout}>logout</MenuItem>
                                </Menu>
                            </Box>
                        </>}

                        {!user.email && <>
                            <div id="rainbowContainer">
                                <a href="https://linktr.ee/draggieland" target="_blank" id="rainbowRibbonLink">
                                    <img class="rainbowRibbon  rainbowRibbonSignedOut" src="/images/rainbowribbon.png" alt="Rainbow Ribbon" title="Draggieland Linktree"/>
                                </a>
                            </div>
                            <Button onClick={(event) => { setProfileAnchor(event.currentTarget) }}>sign in</Button>
                            <Menu anchorEl={profileAnchor} onClose={() => { setProfileAnchor(null) }} open={Boolean(profileAnchor)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} disableScrollLock={true} sx={{ transform: 'translateX(-150px)' }}>
                                <SignIn clientid={google} handle={googleSignIn}></SignIn>
                            </Menu>
                        </>}
                    </Box>
                </Stack>
            </AppBar>
        )
    }

    // mobile navbar
    else if (mobile)
        return (
            <AppBar position='sticky' sx={{ paddingY: '0%', width: '100%', backgroundColor: '#843a3a' }}>
                <Stack direction='row' alignItems='center' justifyContent='space-between' position='relative' paddingLeft='5%' paddingRight='2%'>
                    <MenuIcon onClick={() => setOpenMenu(true)}></MenuIcon>

                    <Drawer open={openMenu} anchor='left' onClose={() => setOpenMenu(false)} className="no-shadow" PaperProps={{ sx: { paddingRight: '5%', paddingTop: '2%' } }}>
                        <a href="/" onClick={() => navigate("/")}><img src="images/logos/tamu-aotw-banner-no-bg.png" height="50" alt="Link to Album of the Week Home"></img></a>
                        <Divider />
                        <Box onClick={() => setOpenMenu(false)}>
                            <MenuItem href='/about' onClick={() => navigate('/about')}>about</MenuItem>
                            {phase !== null && phase !== 'winning album' &&
                                <MenuItem href='/selection' onClick={() => navigate('/selection')}>{phase}</MenuItem>
                            }
                            <MenuItem href='/ratings' onClick={() => navigate('/ratings')}>past discussions</MenuItem>
                            <MenuItem href='/register' onClick={() => navigate('/register')}>register</MenuItem>
                            <MenuItem href="https://tamu.estore.flywire.com/products?storeCatalog=8752" target="_blank" component={Link}>store</MenuItem>
                        </Box>
                    </Drawer>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {user.email &&<>
                            <div id="rainbowContainerMobile">
                                <a href="https://linktr.ee/draggieland" target="_blank">
                                <img class="rainbowRibbonMobile"  src="/images/rainbowribbon.png" alt="Rainbow Ribbon" title="Draggieland Linktree"/>
                                </a>
                            </div>
                            <Box>
                                <Button onClick={(event) => { setProfileAnchor(event.currentTarget) }}>
                                    <Avatar src={user.pfp}></Avatar>
                                </Button>
                                <Menu anchorEl={profileAnchor} onClose={() => { setProfileAnchor(null) }} open={Boolean(profileAnchor)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} disableScrollLock={true}>
                                    <MenuItem href='/profile' onClick={() => navigate('/profile')}>profile</MenuItem>
                                    <Divider></Divider>
                                    <MenuItem onClick={logout}>logout</MenuItem>
                                </Menu>
                            </Box>
                        </>}

                        {!user.email &&<>
                            <div id="rainbowContainerMobile">
                                <a href="https://linktr.ee/draggieland" target="_blank">
                                <img class="rainbowRibbonMobile rainbowRibbonMobileSignedOut"  src="/images/rainbowribbon.png" alt="Rainbow Ribbon" title="Draggieland Linktree"/>
                                </a>
                            </div>
                            <Box paddingY='10px' marginRight='20px'>
                                <Typography variant='h6' onClick={(event) => { setProfileAnchor(event.currentTarget) }}>sign in</Typography>
                                <Menu anchorEl={profileAnchor} onClose={() => { setProfileAnchor(null) }} open={Boolean(profileAnchor)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} disableScrollLock={true} >
                                    <Box width='300px'>
                                        <SignIn clientid={google} handle={googleSignIn}></SignIn>
                                    </Box>
                                </Menu>
                            </Box>
                        </>}
                    </Box>
                </Stack>
            </AppBar>
        )

    // log out user
    function logout() {
        setUser({ registration_data: {} })
        localStorage.removeItem('fname')
        localStorage.removeItem('lname')
        localStorage.removeItem('email')
        localStorage.removeItem('pfp')
        setProfileAnchor(null)
    }

    // sign in user
    async function googleSignIn(decoded) {
        // check registration / dues status
        let dues = await getDuesInfo(decoded.email)

        setUser({ firstName: decoded.given_name, lastName: decoded.family_name, email: decoded.email, pfp: decoded.picture, registration_data: dues })
        localStorage.setItem('fname', decoded.given_name)
        localStorage.setItem('lname', decoded.family_name)
        localStorage.setItem('email', decoded.email)
        localStorage.setItem('pfp', decoded.picture)
        setProfileAnchor(null)
    }

    /* get dues info:
        {
            paid: true if user has paid dues,
            required: true if dues are required,
            registered: true if user is registered
        }
    */
    async function getDuesInfo(email) {
        return await new Promise((resolve, reject) => {
            let options = {
                method: 'GET',
                url: '/api/check-registration-status',
                params: { email: email }
            }
            axios.request(options).then(res => {
                resolve(res.data)
            }).catch(err => {
                resolve({ paid: false, required: true, registered: false })
                console.log('Failed checking registration status')
            })
        })
    }
}

export default NavBar