import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Button, Card, Stack, Select, MenuItem, Chip, Box, Grid, RadioGroup, Radio, FormControlLabel, FormControl, Fab, TextField } from '@mui/material'

import RatingButtons from '../../components/RatingButtons.js'

import { UserContext } from '../../contexts/UserContext.js'
import SubmitButton from '../../components/SubmitButton.js'
import StatusCards from '../../components/StatusCards.js'
import { MobileContext } from '../../contexts/MobileContext.js'
import Loading from '../../components/Loading.js';

import LensIcon from '@mui/icons-material/Lens.js';

export default function Rating() {
  const { user } = useContext(UserContext)
  const { mobile } = useContext(MobileContext)

  const [rating, setRating] = useState(0)
  const [favorites, setFavorites] = useState([]) // stores a list of the names of the selected tracks
  const [tracks, setTracks] = useState([]) // stores all tracks of the album
  const [numToSelect, setNumToSelect] = useState(0) // number of favorites to select
  const [discussion, setDiscussion] = useState({})
  const [messages, setMessages] = useState({}) // stores error and success messages
  const [feedbackQuestions, setFeedbackQuestions] = useState({})
  const [feedbackResponses, setFeedbackResponses] = useState([])


  const validUser = user.email && user.registration_data.registered


  useEffect(() => {
    getRating()
    getFeedbackQuestions()
  }, [])

  useEffect(() => {
    if (user.email) getUserRating()
  }, [user])

  if (!mobile)
    return (
      <Box className='center black-bg' paddingX='5%' paddingBottom='7%' minHeight='80vh'>
        {!validUser &&
          <Grid width='50%' height='100%' justifyContent='center' display='flex' alignItems='center' marginX='25%' paddingY='6%'>
            <StatusCards verb='rating'></StatusCards>
          </Grid>
        }

        {validUser &&
          <>
            <Typography variant="h3" padding='2%' color='white'>rate {discussion.ALBUM} by {discussion.ARTIST} </Typography>

            <Stack alignItems="center" justifyContent="center" sx={{
              padding: '2%',
            }}>
              <Card sx={{ padding: '2%', borderRadius: '20px' }}>
                <Stack direction='column' alignContent='center' justifyContent='center' alignItems='center' spacing={4}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                    <img src='../../images/little-guys/rating.png' width='250vw'></img>
                    <img src={discussion.IMAGE} width='300vw' className='right-shadow'></img>
                    <img src='../../images/little-guys/review.png' width='250vw'></img>
                  </Stack>

                  <Box width='90%' paddingY='3%'>
                    <RatingButtons rating={rating} handle={(e) => setRating(e)}></RatingButtons>
                  </Box>

                  {tracks && tracks.length > 0 &&
                    <Stack spacing={2} alignItems='center'>
                      <Typography variant='h6'>{numToSelect === 1 ? 'favorite track' : `top ${numToSelect} tracks:`}</Typography>
                      <Select sx={{ minWidth: '10vw' }}
                        value={favorites}
                        onChange={(e) => { setFavorites(favorites.length < numToSelect ? e.target.value : e.target.value.slice(0, numToSelect)) }}
                        multiple={true}
                        renderValue={(selected) => (
                          <Stack direction='column' spacing={1}>
                            {selected.map(value => (
                              <Chip label={value}></Chip>
                            ))}
                          </Stack>
                        )}
                      >
                        {tracks.map(track => (
                          <MenuItem value={track.name}>{track.name}</MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  }
                </Stack>
              </Card>
              {feedbackQuestions === undefined && <Loading />}

              {feedbackQuestions && feedbackResponses && feedbackQuestions.length > 0 && feedbackQuestions.map((question, counter) => (
                <Stack sx={{
                  paddingBottom: "1.5%",
                  paddingTop: "3%",
                  width: "100%"
                }} direction='column'>
                  <Typography sx={{
                    color: "white",
                    fontSize: "2vw",
                    paddingBottom: "1%"
                  }}>
                    {question.QUESTION}
                  </Typography>
                  <TextField fullWidth multiline
                    value={feedbackResponses.at(counter).RESPONSE}
                    onChange={(e) => { setFeedbackResponsesHelper(counter, question.QUESTION_ID, e.target.value) }}
                    sx={{
                      marginBottom: '10px',
                      backgroundColor: "#fffef2",
                    }}>
                  </TextField>
                </Stack>
              )) &&
                <Typography sx={{
                  color: "white",
                  fontSize: "2vw",
                  paddingBottom: "1%"
                }}>
                  *all answers are anonymous, no identifying information is tracked with answers to the above questions
                </Typography>
              }
              {feedbackQuestions && feedbackQuestions.length === 0 && <></>}
            </Stack>

            <Box paddingTop='2%'>
              <SubmitButton action={submitRating} text='submit' completed={messages.success} disabled={favorites.length !== numToSelect || rating === undefined || rating === 0} sx={{
              }}></SubmitButton>
            </Box>

            <Typography sx={{ color: 'red' }} align='center' variant='h2' id='display'>{messages.error}</Typography>
          </>
        }

      </Box>
    )


  if (mobile)
    return (
      <Box className='img-bg' minHeight='100vh' margin='0' padding='0' >
        {!validUser &&
          <Box paddingTop='10%'>
            <StatusCards verb='rating'></StatusCards>
          </Box>
        }

        {validUser &&
          <Stack justifyContent='center' display='flex' paddingY='10%' alignItems='center' paddingBottom='15%'>
            <Box borderRadius='10px' width='80%' paddingX='2%' paddingY='5%' className='tan-bg'>
              <Stack direction='column' justifyContent='center' alignItems='center'>
                <Typography fontWeight='bold' variant='h5' textAlign='center'>rate and pick your favorite tracks:</Typography>
                <img className='right-shadow-mobile' src={discussion.IMAGE} width='250vw'></img>
                <Box textAlign='left' width='80%' paddingTop='2%'>
                  <Typography variant='h3' fontWeight='bold'>title: {discussion.ALBUM}</Typography>
                  <Typography variant='h4' fontWeight='bold'>artist: {discussion.ARTIST}</Typography>
                </Box>
                <RatingButtons rating={rating} handle={(e) => setRating(e)} />
                <Typography variant='h6'>(your personal rating)</Typography>
              </Stack>
            </Box>

            {tracks && tracks.length > 0 &&
              <Box borderRadius='10px' width='80%' paddingX='2%' paddingY='5%' className='red-bg' marginTop='10%' color='white'>
                <Stack direction='column' paddingX='2%'>
                  <Typography variant='h4' fontStyle='italic'>pick your favorite {numToSelect} tracks</Typography>
                  <FormControl>
                    {tracks.map((track) => (
                      <Box paddingY='1%'>
                        <FormControlLabel
                          value={track.name}
                          label={<Typography variant='h5'>{track.name}</Typography>}
                          checked={favorites.includes(track.name)}
                          control={<Radio sx={{ '&, &.Mui-checked': { color: 'white' } }} checked={favorites.includes(track.name)} />}
                          onClick={(e) => {
                            track = e.target.value
                            if (track === undefined) return
                            let temp = [...favorites]
                            if (temp.includes(track)) {
                              temp = temp.filter((value) => value !== track)
                              setFavorites(temp)
                            }
                            else if (favorites.length < numToSelect) {
                              temp.push(track)
                              setFavorites(temp)
                            }
                          }}
                        />
                      </Box>
                    ))}
                  </FormControl>
                </Stack>
              </Box>
            }

            {feedbackQuestions === undefined && <Loading />}

            {feedbackQuestions && feedbackResponses && feedbackQuestions.length > 0 &&
              <Box
                borderRadius='10px'
                width='80%'
                paddingX='2%'
                paddingY='5%'
                className='red-bg'
                marginTop='10%'
                color='white'
              >{feedbackQuestions.map((question, counter) => (
                <Stack sx={{
                  paddingBottom: "1.5%",
                  paddingTop: "3%",
                  width: "100%"
                }} direction='column'>
                  <Typography sx={{
                    color: "white",
                    paddingBottom: "1%"
                  }} variant='h5'>
                    {question.QUESTION}
                  </Typography>
                  <TextField fullWidth multiline
                    value={feedbackResponses.at(counter).RESPONSE}
                    onChange={(e) => { setFeedbackResponsesHelper(counter, question.QUESTION_ID, e.target.value) }}
                    sx={{
                      marginBottom: '10px',
                      backgroundColor: "#fffef2",
                    }}>
                  </TextField>
                </Stack>
              ))}
              </Box>}
            {feedbackQuestions && feedbackQuestions.length === 0 && <></>}
            <Box marginTop='8%'>
              <SubmitButton text='submit' action={() => submitRating()} completed={messages.success} disabled={rating === undefined || rating === 0 || (favorites.length !== numToSelect && tracks && tracks.length > 0)} />
            </Box>
          </Stack>
        }
      </Box>
    )

  // gets data about the discussion / album
  function getRating() {
    let options = {
      method: 'GET',
      url: '/api/discussion-data'
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        console.log(res.data.message)
        setMessages({ error: res.data.message })
        return
      }
      setDiscussion(res.data.data)
      getSongs(res.data.data.SPOTIFY_ID)
    })
      .catch((err) => {
        console.log(err)
        setMessages({ error: "Error connecting to the database." })
      })
  }

  function getSongs(id) {
    let options = {
      method: 'GET',
      url: '/api/spotify-songs',
      params: { id: id }
    }
    axios.request(options).then((res) => {
      let data = res.data
      if (data.error) {
        setTracks([])
        return
      }
      setTracks(data.sampleSongs)
      setNumToSelect(data.sampleSongs.length > 5 ? 3 : 1)
      console.log("tracks: ")
      console.log(tracks)
    }).catch((err) => {
      console.log(err)
    })
  }

  // signs in user and retreives previous rating data (if any)
  function getUserRating() {

    const options = {
      method: 'GET',
      url: '/api/user-ratings',
      params: { email: user.email }
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        console.log(res.data.error)
        setMessages({ error: "Error recieving previous rating" })
        return
      }
      setRating(res.data.rating)
      if (res.data.favorites)
        setFavorites(res.data.favorites.filter((track) => track))
    })
      .catch((err) => {
        console.log(err)
      })
  }


  // sends their rating to be stored in database
  function submitRating() {
    if (user === null || user.email === undefined) {
      console.log('sign in')
      setMessages({ error: 'Please sign in using your TAMU google account' })
      return
    }

    if (rating === undefined || rating === 0) {
      console.log('no rating')
      setMessages({ error: 'Please enter a rating' })
      return
    }

    if (favorites.length !== numToSelect) {
      setMessages({ error: `Please select ${numToSelect} tracks` })
      return
    }

    let selected = [...favorites] // copy of favorites to add null to when sending to database
    // fill remaining spaces with null if there are any
    for (let i = favorites.length; i < 3; i++) {
      selected.push(null)
    }
    // console.log(feedbackResponses)
    for (let i = 0; i < feedbackResponses.length; i++){
      if (feedbackResponses.at(i).RESPONSE !== ""){
        console.log(feedbackResponses.at(i))
        console.log(feedbackResponses.at(i).QUESTION_ID)
        insertFeedbackResponses(feedbackResponses.at(i))
      }
    }


    // send data to backend
    const options = {
      method: 'POST',
      url: '/api/insert-rating',
      data: { rating: rating, email: user.email, favorite: selected }
    }
    axios.request(options).then((res) => {
      let err = res.data.error
      if (err) {
        console.log(err)
        setMessages({ error: res.data.message })
        return
      }
      console.log(res.data.message)
      setMessages({ success: res.data.message })
    })
      .catch((err) => {
        console.log(err)
        setMessages({ error: "Error connecting to database" })
      })
  }

  function getFeedbackQuestions() {
    const options = {
      method: 'GET',
      url: '/api/get-feedback-questions'
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        setMessages({ error: 'There was an error retrieving the feedback questions.' })
        return
      }
      console.log(res)
      setFeedbackQuestions(res.data.questions)
      const arr = []
      for (let i = 0; i < res.data.questions.length; i++) {
        arr.push({
          QUESTION_ID: 0,
          RESPONSE: ""
        })
      }
      setFeedbackResponses(arr)
    }).catch((err) => {
      console.log(err)
      setMessages({ error: 'There was an error retrieving the feedback questions.' })
    })
  }
  function setFeedbackResponsesHelper(index, questionId, value) {
    const newResponses = feedbackResponses.map((r, idx) => {
      if (idx === index) {
        return {
          QUESTION_ID: questionId,
          RESPONSE: value
        }
      } else {
        return r
      }
    })
    setFeedbackResponses(newResponses)
  }
  function insertFeedbackResponses(response) {
    const options = {
      method: 'POST',
      url: '/api/insert-feedback-response',
      data: { email: user.email, questionID: response.QUESTION_ID, response: response.RESPONSE }
    }
    axios.request(options).then((res) => {
      let err = res.data.error
      if (err) {
        console.log(err)
        setMessages({ error: res.data.message })
        return
      }
      console.log(res.data.message)
      setMessages({ success: res.data.message })
    })
      .catch((err) => {
        console.log(err)
        setMessages({ error: "Error connecting to database" })
      })
  }
}